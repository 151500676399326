<template>
  <Menu as="div" class="relative inline-block w-full my-auto text-left">
    <div>
      <MenuButton
        class="flex items-center justify-between w-full h-10 px-3 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light lg:rounded-md"
        :class="
          darkMode
            ? 'text-white bg-header-darker lg:hover:bg-header-dark ring-offset-header-darker'
            : 'text-header-dark hover:text-header-darkest hover:bg-header-off-white border border-header-lightest ring-offset-header-off-white'
        "
      >
        <span class="text-sm font-medium truncate">
          <span class="sr-only">Open company menu for </span>
          {{ authCompanyTeamMember.company.name }}
        </span>
        <ChevronDownIcon class="block w-5 h-5 ml-1 shrink-0" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="scale-95 opacity-0"
      enter-to-class="scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="scale-100 opacity-100"
      leave-to-class="scale-95 opacity-0"
    >
      <MenuItems
        class="absolute max-h-screen-75 overflow-y-scroll left-0 z-30 w-56 mt-1 origin-top-left bg-white divide-y rounded-md shadow-xl divide-header-off-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="py-1">
          <p class="px-4 pt-2 pb-1 text-sm text-primary">Your current company is</p>
          <div class="flex items-center px-4 py-2 text-sm font-bold text-header-dark">
            <UserGroupIcon class="w-5 h-5 mr-3" aria-hidden="true" />
            <p class="truncate">{{ authCompanyTeamMember.company.name }}</p>
          </div>
          <MenuItem v-if="hasPermission('settings_manage')" v-slot="{ active }">
            <aryeo-link
              :href="route('web.company.auth-company.edit')"
              :class="[
                active ? 'bg-header-off-white text-header-darkest' : 'text-header-dark',
                'group flex items-center px-4 py-2 text-sm',
              ]"
            >
              <PencilAltIcon
                class="w-5 h-5 mr-3 text-header-lighter group-hover:text-header-light"
                aria-hidden="true"
              />
              Edit Current Company
            </aryeo-link>
          </MenuItem>
        </div>
        <div v-if="hasOtherCompanyTeamMembers" class="py-1">
          <p class="px-4 py-2 text-sm text-primary">Switch company to</p>
          <MenuItem
            v-for="companyTeamMember in otherCompanyTeamMembers"
            :key="companyTeamMember.id"
            v-slot="{ active }"
            @click.prevent="switchSelectedCompanyTeamMember(companyTeamMember.id)"
          >
            <div
              :class="[
                active ? 'bg-header-off-white text-header-darkest' : 'text-header-dark',
                'group flex items-center px-4 py-2 text-sm cursor-pointer',
              ]"
            >
              <UserGroupIcon
                class="flex-none w-5 h-5 mr-3 text-header-lighter group-hover:text-header-light"
                aria-hidden="true"
              />
              <p class="truncate">{{ companyTeamMember.company.name }}</p>
            </div>
          </MenuItem>
        </div>
        <div v-if="hasOtherCompanyTeamMembers" class="py-1">
          <MenuItem v-slot="{ active }">
            <a
              :href="route('web.company.auth-companies.index')"
              :class="[
                active ? 'bg-header-off-white text-primary-dark' : 'text-primary',
                'group flex items-center px-4 py-2 text-sm',
              ]"
            >
              <ViewListIcon
                class="w-5 h-5 mr-3 text-primary-lighter group-hover:text-primary-light"
                aria-hidden="true"
              />
              View All Companies
            </a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import { computed } from 'vue'
import { router } from '@inertiajs/vue3'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon, PencilAltIcon, UserGroupIcon, ViewListIcon } from '@heroicons/vue/solid'
import { usePermissions } from '@/Hooks/Company/usePermissions'

import { useAuth } from '@/Hooks/Company/useAuth'

defineProps({
  darkMode: { type: Boolean, default: true },
})

const { authCompanyTeamMember, authCompanyTeamMembers } = useAuth()
const { hasPermission } = usePermissions()

const otherCompanyTeamMembers = computed(() => {
  return authCompanyTeamMembers.value.data
    .filter((companyTeamMember) => companyTeamMember.id !== authCompanyTeamMember.value.id)
    .filter((companyTeamMember) => companyTeamMember.company)
    .sort((a, b) => {
      return a.company?.name.localeCompare(b.company?.name)
    })
})

const hasOtherCompanyTeamMembers = computed(() => otherCompanyTeamMembers.value.length > 0)

const switchSelectedCompanyTeamMember = async (selectedCompanyTeamMemberId) => {
  router.post(route('web.company.auth-company-user.selected-company-team-member.update'), {
    company_team_member_id: selectedCompanyTeamMemberId,
  })
}
</script>
