<template>
  <div class="fixed top-0 right-0 z-60 m-6">
    <transition-group name="slide-fade">
      <div
        v-for="(flash, index) in flashes"
        :key="index"
        class="relative px-8 py-6 pr-12 mb-4 rounded-lg shadow-xl"
        :class="{
          'text-emerald-900 bg-emerald-200': flash.type === 'success',
          'text-blue-900 bg-blue-200': flash.type === 'info',
          'text-red-900 bg-red-200': flash.type === 'error',
        }"
        style="min-width: 240px"
      >
        <button
          class="absolute top-0 right-0 px-4 py-3 mt-1 text-2xl opacity-75 cursor-pointer hover:opacity-100 focus:outline-none focus:opacity-100"
          @click="removeFlash(flash.id)"
        >
          &times;
        </button>

        <div class="flex items-center text-sm font-medium">
          {{ flash.message }}
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script setup>
import { useFlash } from '@/Hooks/Shared/useFlash.js'
import { watch, onMounted } from 'vue'
import { usePage } from '@inertiajs/vue3'

const page = usePage()

const { flashes, flashSuccess, flashError, removeFlash } = useFlash()

const laravelErrorBagsToIgnore = ['strong_customer_authentication']

function addFlashMessages(pageProps) {
  const flash = pageProps.flash
  if (!flash) {
    return
  }

  if (flash.success) {
    flashSuccess(flash.success)
  }
  if (flash.error) {
    flashError(flash.error)
  }
}

onMounted(() => {
  addFlashMessages(page)
})

watch(page, (page) => {
  if (page.props.errors) {
    if (shouldBypassErrors(page.props.errors)) {
      return
    }

    const errorCount = Object.keys(page.props.errors).length

    if (errorCount > 0) {
      let msg = 'There is one form error'
      if (errorCount > 1) {
        msg = `There are ${errorCount} form errors.`
      }

      flashError(msg)
    }
  }

  addFlashMessages(page.props)
})

function shouldBypassErrors(errors) {
  const errorKeys = Object.keys(errors)

  if (errorKeys.length !== 1) {
    return false
  }

  return laravelErrorBagsToIgnore.includes(errorKeys[0])
}
</script>

<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.4s;
}

.slide-fade-enter-from,
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(400px);
  opacity: 0;
}
</style>
