<template>
  <Menu as="div" class="relative inline-block my-auto text-left">
    <div>
      <MenuButton
        class="flex items-center justify-center h-10 px-3 text-sm rounded-md bg-header-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light lg:rounded-md lg:hover:bg-header ring-offset-header-dark"
        :class="impersonation ? 'text-amber-400' : 'text-white'"
      >
        <Tooltip v-if="impersonation" class="block mr-3" text="Impersonation Mode Active">
          <Badge text="IM" color="warning" />
        </Tooltip>
        <span class="block mr-3 text-sm font-medium truncate"
          ><span class="sr-only">Open user menu for </span> {{ authCompanyUser.first_name }}
          {{ authCompanyUser.last_name }}</span
        >
        <Avatar
          :firstName="authCompanyUser.first_name"
          :lastName="authCompanyUser.last_name"
          :image="authCompanyUser.avatar_url"
          size="xsmall"
        />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="scale-95 opacity-0"
      enter-to-class="scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="scale-100 opacity-100"
      leave-to-class="scale-95 opacity-0"
    >
      <MenuItems
        class="absolute right-0 z-30 w-56 mt-1 origin-top-right bg-white divide-y rounded-md shadow-lg divide-header-off-white-dark ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div v-if="impersonation" class="p-2">
          <div class="p-4 rounded-md bg-amber-100">
            <Stack space="medium">
              <Stack space="xxsmall">
                <Typography variant="body" color="warning-dark">
                  You are currently in impersonation mode, viewing as:
                </Typography>
                <div
                  class="w-full flex items-center justify-between h-10 px-3 text-sm rounded-md bg-amber-200 border-1 border-amber-300"
                >
                  <Typography variant="label" color="warning-dark">
                    {{ authCompanyTeamMember.company_user.first_name }}
                    {{ authCompanyTeamMember.company_user.last_name }}
                  </Typography>
                  <Avatar
                    :firstName="authCompanyTeamMember.company_user.first_name"
                    :lastName="authCompanyTeamMember.company_user.last_name"
                    :image="authCompanyTeamMember.company_user.avatar_url"
                    size="xsmall"
                  />
                </div>
              </Stack>
              <TextButton
                variant="body-small"
                color="warning"
                @click="stopImpersonating"
                text="Exit Impersonation"
                icon-name="signOut"
              />
            </Stack>
          </div>
        </div>
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <aryeo-link
              :href="route('web.company.auth-company-user.show')"
              :class="[
                active ? 'bg-header-off-white text-header-darkest' : 'text-header-dark',
                'group flex items-center px-4 py-2 text-sm',
              ]"
            >
              <UserCircleIcon
                class="w-5 h-5 mr-3 text-header-lighter group-hover:text-header-light"
                aria-hidden="true"
              />
              Edit User
            </aryeo-link>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <aryeo-link
              v-if="authOrganization.is_pro_account"
              :href="route('web.company.notifications.preferences.edit')"
              :class="[
                active ? 'bg-header-off-white text-header-darkest' : 'text-header-dark',
                'group flex items-center px-4 py-2 text-sm',
              ]"
            >
              <BellIcon
                class="w-5 h-5 mr-3 text-header-lighter group-hover:text-header-light"
                aria-hidden="true"
              />
              Notification Preferences
            </aryeo-link>
            <ItemDisabled
              v-else
              name="Notification Preferences"
              :iconComponent="BellIcon"
              placement="left"
            />
          </MenuItem>
        </div>
        <div class="py-1" v-if="!impersonation">
          <MenuItem v-slot="{ active }">
            <form method="post" :action="route('web.company.login.destroy')">
              <CsrfToken />
              <button
                type="submit"
                :class="[
                  active ? 'bg-header-off-white text-header-darkest' : 'text-header-dark',
                  'group flex items-center px-4 py-2 text-sm w-full',
                ]"
              >
                <LogoutIcon
                  class="w-5 h-5 mr-3 shrink-0 text-header-lighter group-hover:text-header-light"
                  aria-hidden="true"
                />
                Logout
              </button>
            </form>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import { computed } from 'vue'
import { Avatar, Typography, Stack, TextButton, Tooltip } from '@aryeo/runway'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { BellIcon, UserCircleIcon, LogoutIcon } from '@heroicons/vue/solid'

import { useAuth } from '@/Hooks/Company/useAuth'

import CsrfToken from '@/Components/Shared/CsrfToken.vue'
import Badge from '@/Runway/Elements/Badge.vue'
import ItemDisabled from '@/Components/Shared/Navigation/ItemDisabled.vue'

const {
  authCompanyUser,
  authCompanyTeamMember,
  authOrganization,
  impersonation,
  stopImpersonating,
} = useAuth()
</script>
