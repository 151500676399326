import NProgress from 'nprogress'
import { createApp, h } from 'vue'
import { createInertiaApp, router } from '@inertiajs/vue3'
import MainCompanyLayout from '@/Layouts/Company/MainCompanyLayout.vue'
import MainCustomerLayout from '@/Layouts/Customer/MainCustomerLayout.vue'
import Link from '@/Components/Shared/Link/Link.js'
import * as Sentry from '@sentry/vue'
import Sortable, { MultiDrag } from 'sortablejs'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'

import '../css/v2.css'
import 'intl-tel-input/build/css/intlTelInput.css'

// Mount this here so that it only happens once
Sortable.mount(new MultiDrag())

// Define UploadCare translations accessible to `window`
window.UPLOADCARE_LOCALE_TRANSLATIONS = {
  errors: {
    fileMaximumSize: 'File is too big, the maximum file size is 10MB.',
    imageMinimumResolution: 'Image is too small, the minimum image size is 800px by 600px.',
    videoFileType: 'This file does not appear to be a video.',
  },
}

const globalHelpers = {
  install: (app, options) => {
    app.mixin({
      computed: {
        pageURL() {
          return this.$page.url
        },
      },
      methods: {
        route: window.route,
        isPath(url) {
          return url === `${window.location.origin}${window.location.pathname}`
        },
      },
    })
  },
}

createInertiaApp({
  resolve: (name) =>
    resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')).then(
      (module) => {
        if (module.default.layout === undefined) {
          let layout = null
          switch (name.split('/')[0]) {
            case 'Company':
              layout = MainCompanyLayout
              break
            case 'Customer':
              layout = MainCustomerLayout
              break
            default:
              break
          }

          module.default.layout = layout
        }

        return module
      }
    ),
  progress: {
    // The delay after which the progress bar will
    // appear during navigation, in milliseconds.
    delay: 250,

    // The color of the progress bar.
    color: '#e5e7eb',

    // Whether to include the default NProgress styles.
    includeCSS: true,

    // Whether the NProgress spinner will be shown.
    showSpinner: true,
  },
  setup({ el, App, props, plugin }) {
    const vueApp = createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(globalHelpers)
      .component('aryeo-link', Link)

    if (window.ARYEO_SENTRY_DSN) {
      Sentry.init({
        environment: window.ARYEO_ENVIRONMENT,
        app: vueApp,
        dsn: window.ARYEO_SENTRY_DSN,
        denyUrls: [
          // Chrome extensions
          /extensions\//i,
          /^chrome:\/\//i,
          /^chrome-extension:\/\//i,
          // Facebook flakiness
          /graph\.facebook\.com/i,
          // Google Analytics and Google Tag Manager
          /\/(gtm|ga|analytics)\.js/i,
          // Facebook blocked
          /connect\.facebook\.net\/en_US\/all\.js/i,
          // Firefox Extensions
          /^resource:\/\//i,
          // Safari Extensions
          /.*@webkit-masked-url.*/,
          // Cookie Consent Banners via GTM
          /otSDKStub.js/i,
          /cookielaw.org/i,
        ],
        integrations: [
          new Sentry.BrowserTracing({
            tracePropagationTargets: [window.ARYEO_COMPANY_API_V1_BASE_URL],
            routingInstrumentation: function (
              startTransaction,
              startTransactionOnPageLoad,
              startTransactionOnLocationChange
            ) {
              const tags = {
                'routing.instrumentation': 'inertia',
              }
              if (startTransactionOnPageLoad) {
                startTransaction({
                  name: window.location.pathname,
                  op: 'pageload',
                  tags,
                  metadata: {
                    source: 'url',
                  },
                })
              }

              if (startTransactionOnLocationChange) {
                router.on('start', function (event) {
                  startTransaction({
                    name: event.detail.visit.url.pathname,
                    op: 'navigation',
                    tags,
                    data: {
                      from: window.location.pathname,
                      to: event.detail.visit.url.pathname,
                      query: event.detail.visit.url.search,
                    },
                  })
                })
              }
            },
          }),
          new Sentry.Replay({
            maskAllText: false,
            maskAllInputs: true,
          }),
        ],
        // Performance Monitoring
        tracesSampleRate: 0, // Percentage of transactions to be sent to Sentry. Default is 0.1 (10%)
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      })
    }

    router.on('start', () => NProgress.start())
    router.on('finish', () => NProgress.done())

    vueApp.mount(el)
  },
})
