import { reactive } from 'vue'
import { v4 as uuidv4 } from 'uuid'

const flashes = reactive([])

const FLASH_DURATION = 5000

export function useFlash() {
  const removeFlash = (uuid) => {
    const index = flashes.findIndex((flash) => flash.id === uuid)
    if (index >= 0) {
      flashes.splice(index, 1)
    }
  }

  const addFlash = (flash) => {
    const id = uuidv4()

    flashes.push({
      id,
      type: flash.type,
      message: flash.message,
    })

    setTimeout(() => {
      removeFlash(id)
    }, flash.duration || FLASH_DURATION)
  }

  const flashSuccess = (message) => {
    addFlash({
      message,
      type: 'success',
    })
  }
  const flashInfo = (message) => {
    addFlash({
      message,
      type: 'info',
    })
  }
  const flashError = (message) => {
    addFlash({
      message,
      type: 'error',
    })
  }

  return {
    flashes,
    flashSuccess,
    flashInfo,
    flashError,
    removeFlash,
  }
}
