<template>
  <div
    v-if="title || description"
    class="pb-5 mb-6 space-y-3 border-b border-gray-200 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0"
  >
    <div class="flex-1 min-w-0">
      <h3 class="text-3xl font-semibold leading-6 text-gray-900">
        {{ title }}
      </h3>
      <p v-if="description" class="mt-3 text-sm text-gray-500">
        {{ description }}
      </p>
    </div>
    <div>
      <span class="rounded-md shadow-sm">
        <slot name="actions" />
      </span>
    </div>
  </div>
</template>

<script>
import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'
export default {
  setup() {
    const page = usePage()

    const title = computed(() => page.props.meta.title)
    const description = computed(() => page.props.meta.description)

    return { title, description }
  },
}
</script>
