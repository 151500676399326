<template>
  <nav
    class="flex flex-wrap items-center text-sm font-medium leading-5"
    :class="{ 'py-4 px-4 sm:px-6': breadcrumb && breadcrumb.length > 0 }"
  >
    <template v-for="(item, index) in breadcrumb" :key="index">
      <component
        :is="item.route_is_inertia === false ? 'a' : 'aryeo-link'"
        :href="getRoute(item)"
        class="text-gray-500 transition duration-150 ease-in-out hover:text-gray-700 focus:outline-none focus:underline"
      >
        {{ item.title }}
      </component>
      <ChevronRightIcon
        v-if="index != Object.keys(breadcrumb).length - 1"
        class="shrink-0 w-5 h-5 mx-2 text-gray-400"
        aria-hidden="true"
      />
    </template>
  </nav>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'
import { ChevronRightIcon } from '@heroicons/vue/solid'

const page = usePage()

const breadcrumb = computed(() => page.props.meta.breadcrumb)

const getRoute = ({ url, route: path, prop = null, key = null }) => {
  if (url) {
    return url
  }

  if (prop) {
    return route(path, page.props[prop].data ? page.props[prop].data[key] : page.props[prop][key])
  }
  return route(path)
}
</script>
