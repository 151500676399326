<template>
  <div>
    <ImpersonationBanner v-if="active" />

    <Teleport v-if="changed && !stoppingImpersonation" to="body">
      <div
        v-if="true"
        class="fixed flex top-0 left-0 w-full h-screen z-51 bg-black bg-opacity-75 my-auto items-center justify-center"
      >
        <div
          class="p-4 sm:p-10 bg-gray-50 rounded-md w-[300px] md:w-[500px] text-center overflow-y-auto"
        >
          <span
            class="mb-4 inline-flex justify-center items-center w-[62px] h-[62px] rounded-full border-4 border-yellow-50 bg-yellow-100 text-yellow-500"
          >
            <Icon color="warning" name="exclamationTriangle" />
          </span>
          <h3 class="mb-2 text-xl text-gray-800">
            <div class="font-bold">You are no longer viewing this page as:</div>
            {{ impersonation.impersonated.full_name }}
          </h3>
          <p class="text-sm text-gray-500">
            You can resume that session or safely close this window
          </p>
          <div class="mt-6 flex justify-center gap-x-4">
            <Button color="primary" text="Resume" @click="resume()" />
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { useAuth } from '@/Hooks/Company/useAuth'
import { useCookies } from '@vueuse/integrations/useCookies'
import ImpersonationBanner from '@/Components/Company/PageWrapper/ImpersonationBanner.vue'
import { Icon, Button } from '@aryeo/runway'

const { impersonation, startImpersonating } = useAuth()
const cookies = useCookies()

const stoppingImpersonation = ref(false)
onMounted(() => {
  window.addEventListener('stoppingImpersonation', () => (stoppingImpersonation.value = true))
})

const active = computed(() => impersonation.value && cookies.get('nova_impersonated_id'))

const changed = computed(
  () => impersonation.value?.impersonated.id !== cookies.get('nova_impersonated_id')
)

const redirect = computed(() => window.location.href)

const resume = () => {
  startImpersonating({
    resource: 'users',
    resourceId: impersonation.value.impersonated.id,
    redirect: redirect.value,
  })
}
</script>
