const ListingDeliveryStatusOptions = [
  {
    label: 'Delivered',
    value: 'delivered',
    color: 'success-lightest',
  },
  {
    label: 'Undelivered',
    value: 'undelivered',
    color: 'danger-lightest',
  },
  {
    label: 'Scheduled',
    value: 'scheduled',
    color: 'warning-lightest',
  },
]

export default ListingDeliveryStatusOptions
