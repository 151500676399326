<template>
  <button
    data-canny-changelog
    class="flex items-center h-10 px-3 rounded-md bg-header-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light hover:bg-header ring-offset-header-dark"
  >
    <LightningBoltIcon class="block w-5 h-5 text-white shrink-0" aria-hidden="true" />
  </button>
</template>

<script setup>
import { onMounted } from 'vue'
import { LightningBoltIcon } from '@heroicons/vue/solid'

onMounted(() => {
  window.Canny('initChangelog', {
    appID: '5f87612dfb339232bde91d06',
    position: 'bottom',
    align: 'right',
  })
})
</script>
