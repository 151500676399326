import { useAuth } from '@/Hooks/Company/useAuth'

export function usePermissions() {
  const { authCompanyTeamMember } = useAuth()

  const permissions = authCompanyTeamMember.value?.permissions

  function hasPermission(permissionName) {
    if (!permissions) {
      return false
    }

    return permissions.map((permission) => permission.name).includes(permissionName)
  }

  return {
    hasPermission,
  }
}
