import { LinkIcon } from '@heroicons/vue/outline'
import { useAuth } from '@/Hooks/Company/useAuth'
import { usePermissions } from '@/Hooks/Company/usePermissions'

export default function useOrderFormNavigation() {
  const { authOrganization } = useAuth()
  const { hasPermission } = usePermissions()

  return {
    sections: [
      {
        show: hasPermission('order_form_view_any'),
        disabled: !authOrganization.value.is_pro_account,
        name: 'Embed Settings',
        description: '',
        routeIsInertia: true,
        targetRouteName: 'web.company.order-landing.settings.show',
        icon: LinkIcon,
        routeNamesToCheckIfCurrent: ['web.company.order-landing.*'],
      },
    ],
  }
}
