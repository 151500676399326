<template>
  <div class="flex flex-col h-screen">
    <PortalAppBanner
      v-if="page.props.show_portal_app_banner"
      :portalAppBanner="page.props.portal_app_banner"
    />
    <Header />
    <div class="z-10 flex flex-1 overflow-hidden">
      <Sidebar v-if="renderDesktopSidebar" />
      <main scroll-region class="flex-1 w-full overflow-y-scroll">
        <DefaultPageWrapper v-if="renderDefaultPageWrapper">
          <slot />
        </DefaultPageWrapper>
        <slot v-else />
      </main>
    </div>

    <FlashMessage class="mt-20" />

    <GoogleTagManagerScripts
      v-if="page.props.tenant.company.google_tag_manager_id"
      :googleTagManagerId="page.props.tenant.company.google_tag_manager_id"
    />
  </div>
</template>

<script setup>
import { ref, provide, computed } from 'vue'
import { usePage } from '@inertiajs/vue3'

import DefaultPageWrapper from '@/Components/Company/PageWrapper/DefaultPageWrapper.vue'
import FlashMessage from '@/Components/Shared/FlashMessage.vue'
import Header from '@/Components/Customer/Header/Header.vue'
import PortalAppBanner from '@/Components/Shared/PortalAppBanner.vue'
import Sidebar from '@/Components/Customer/Sidebar/Sidebar.vue'

import GoogleTagManagerScripts from '@/Components/Customer/OrderForms/LayoutParts/GoogleTagManagerScripts.vue'

const page = usePage()

const renderDesktopSidebar = computed(() => page.props.meta?.renderDesktopSidebar ?? true)

const renderDefaultPageWrapper = computed(() => page.props.meta?.renderDefaultPageWrapper ?? true)

const sidebarOpen = ref(false)
provide('sidebarOpen', sidebarOpen)

const toggleSidebarOpen = () => (sidebarOpen.value ^= true)
provide('toggleSidebarOpen', toggleSidebarOpen)
</script>
