<template>
  <component
    :is="linkComponent"
    :href="targetUrl"
    :ghost="ghost"
    @click="handleItemClick"
    :class="[
      isCurrentForRouteNames
        ? 'bg-gray-100 text-gray-900 hover:text-gray-900 hover:bg-gray-100'
        : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50',
      'w-full group flex items-center px-3 py-2 text-sm font-medium rounded-md focus:outline-none focus:bg-gray-100',
    ]"
  >
    <Logo v-if="image" :image="image" size="xsmall" class="mr-3"></Logo>
    <component
      v-else
      :is="iconComponent"
      :class="[
        isCurrentForRouteNames ? 'text-gray-600' : 'text-gray-500 group-hover:text-gray-600',
        'mr-3 shrink-0 h-5 w-5',
      ]"
      aria-hidden="true"
    />

    <span class="flex-1 text-left">
      {{ name }}
    </span>
    <div
      v-if="name === 'Orders' && ordersRequiringActionCount > 0"
      class="ml-auto inline-flex items-center px-2.5 py-0.5 -my-0.5 rounded-md text-sm font-medium bg-amber-100 text-amber-800"
    >
      {{ ordersRequiringActionCount }}
    </div>
    <Badge v-if="badge" :text="badge" textVariant="label-small" />
    <span
      v-if="tag"
      :class="[
        isCurrentForRouteNames ? 'bg-white' : 'bg-gray-100 group-hover:bg-gray-200',
        'ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full',
      ]"
    >
      {{ tag }}
    </span>
  </component>
</template>

<script setup>
import { computed } from 'vue'
import { Logo } from '@aryeo/runway'
import Badge from '@/Runway/Elements/Badge.vue'

import { useCurrentRoute } from '@/Hooks/Shared/useCurrentRoute.js'

const emits = defineEmits(['itemClicked'])

const props = defineProps({
  name: String,
  image: String,
  iconComponent: Function,
  tag: String,
  targetRouteName: String,
  targetRouteAttributes: Object,
  isInertiaLink: Boolean,
  routeNamesToCheckIfCurrent: Array,
  itemOfPrimaryColumn: Boolean,
  ghost: {
    type: Boolean,
    default: false,
  },
  ordersRequiringActionCount: {
    type: Number,
    default: 0,
  },
  badge: String,
})

const { isCurrentForRouteNames } = useCurrentRoute(props.routeNamesToCheckIfCurrent)

const linkComponent = computed(() => (props.isInertiaLink ? 'aryeo-link' : 'a'))
const targetUrl = computed(() =>
  props.targetRouteName ? route(props.targetRouteName, props.targetRouteAttributes) : '#'
)

const handleItemClick = (e) => {
  emits('itemClicked', e)
}
</script>
