import axios from 'axios'

export default axios.create({
  baseURL: window.ARYEO_COMPANY_API_V1_BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'accept-language': navigator.language,
  },
})
