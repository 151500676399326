<template>
  <div class="mx-6">
    <h3 v-if="name" class="mb-3 text-xs font-semibold tracking-wider text-primary uppercase">
      {{ name }}
    </h3>
    <div class="space-y-1" :class="{ '-mx-3': useNegativeMargin }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    name: String,
    useNegativeMargin: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {}
  },
}
</script>
