export function formatCurrency(value, currency = 'USD', locale = null) {
  const computedLocale = locale ?? navigator?.language ?? 'en-US'
  return value.toLocaleString(computedLocale, {
    style: 'currency',
    currency,
  })
}

export function formatCents(value, currency = 'USD', locale = null) {
  return formatCurrency(centsToDecimal(value), currency, locale)
}

export function formatCentsWithoutCurrencySymbol(value) {
  return centsToDecimal(value).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export function getCurrencySymbol(currency = 'USD', locale = null) {
  const computedLocale = locale ?? navigator?.language ?? 'en-US'
  return (0)
    .toLocaleString(computedLocale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim()
}

export function centsToDecimal(value) {
  return Math.round((value / 100) * 100) / 100
}

export function decimalToCents(value) {
  return Math.round(value * 100)
}
