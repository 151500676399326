import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'

export function useOrdersRequiringAction() {
  const page = usePage()

  const count = computed(() => page.props.ordersRequiringActionCount)

  return {
    count,
  }
}
