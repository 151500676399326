<template>
  <SharedSidebarItem
    :name="name"
    :image="image"
    :iconComponent="iconComponent"
    :tag="tag"
    :targetRouteName="targetRouteName"
    :targetRouteAttributes="targetRouteAttributes"
    :isInertiaLink="isInertiaLink"
    :routeNamesToCheckIfCurrent="routeNamesToCheckIfCurrent"
    :itemOfPrimaryColumn="itemOfPrimaryColumn"
    :ghost="ghost"
    :badge="badge"
    @itemClicked="handleItemClick"
  />
</template>

<script setup>
import SharedSidebarItem from '@/Components/Shared/Navigation/Sidebar/SidebarItem.vue'

const props = defineProps({
  name: String,
  image: String,
  iconComponent: Function,
  tag: String,
  targetRouteName: String,
  targetRouteAttributes: Object,
  isInertiaLink: Boolean,
  routeNamesToCheckIfCurrent: Array,
  itemOfPrimaryColumn: Boolean,
  ghost: {
    type: Boolean,
    default: false,
  },
  badge: String,
})

const handleItemClick = (e) => {
  if (props.itemOfPrimaryColumn) {
    e.preventDefault()
  }
}
</script>
