import BaseApi from './BaseApi'

export default {
  index(search = '') {
    const params = new URLSearchParams()

    if (search) {
      params.append('filter[search]', search)
    }

    return BaseApi.get(route('api.command-palette.commands.index'), {
      params,
    })
  },
}
