<template>
  <header class="relative z-20 flex shrink-0 h-14">
    <div
      class="content-center justify-center hidden w-64 shrink-0 bg-header-darker md:flex h-14 md:px-3"
    >
      <CompanyDropdown />
    </div>
    <div
      class="flex items-center justify-between flex-1 px-4 space-x-6 bg-header-dark h-14 md:px-6"
    >
      <div class="flex items-center w-full md:w-auto">
        <GlobalSearch />
      </div>

      <div class="z-20 items-center hidden space-x-2 md:flex">
        <CannyWidget />
        <HelpDropdown />
        <CompanyUserDropdown />
      </div>
    </div>
    <div class="flex content-center justify-center px-4 shrink-0 bg-header-darker md:hidden h-14">
      <button
        class="block text-header-lighter hover:text-header-off-white-dark focus:outline-none focus:text-header-off-white-dark"
        @click="toggleSidebarOpen"
      >
        <svg
          viewBox="0 0 24 24"
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M3 12h18M3 6h18M3 18h18" />
        </svg>
      </button>
    </div>
  </header>
</template>

<script setup>
import { inject } from 'vue'
import CompanyDropdown from '@/Components/Company/Header/CompanyDropdown.vue'
import GlobalSearch from '@/Components/Company/Header/GlobalSearch.vue'
import HelpDropdown from '@/Components/Company/Header/HelpDropdown.vue'
import CompanyUserDropdown from '@/Components/Company/Header/CompanyUserDropdown.vue'
import CannyWidget from '@/Components/Company/Header/CannyWidget.vue'

const toggleSidebarOpen = inject('toggleSidebarOpen')
</script>
