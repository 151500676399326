<template>
  <Tag :label="paymentStatusOption?.label ?? 'No Status'" :color="color" :isSmall="isSmall" />
</template>
<script setup>
import { computed } from 'vue'
import { Tag } from '@aryeo/runway'

import PaymentStatusOptions from '@/Utils/Orders/PaymentStatusOptions'

const props = defineProps({
  paymentStatus: {
    type: String,
    required: true,
  },
  isSmall: {
    type: Boolean,
    default: false,
  },
  orderNumber: {
    type: String,
    default: null,
  },
})

const paymentStatusOption = computed(() => {
  return PaymentStatusOptions.find((status) => status.value === props.paymentStatus)
})

const color = computed(() => {
  return paymentStatusOption.value?.color ?? 'secondary'
})
</script>
