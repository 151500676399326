<template>
  <input type="hidden" :value="csrfToken" name="_token" />
</template>

<script>
import { useCsrfToken } from '@/Hooks/Shared/useCsrfToken'

export default {
  setup() {
    const { csrfToken } = useCsrfToken()

    return {
      csrfToken,
    }
  },
}
</script>
