<template>
  <div class="relative py-1" @click.prevent="handleClick">
    <span class="absolute inset-y-0 left-0 flex items-center pl-2">
      <svg
        viewBox="0 0 24 24"
        class="w-5 h-5 text-header-light"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <circle cx="11" cy="11" r="8" />
        <line x1="21" y1="21" x2="16.65" y2="16.65" />
      </svg>
    </span>
    <input
      class="cursor-pointer block w-full py-2 pr-4 text-sm text-white placeholder-header-lighter bg-header-darker rounded-md pl-9 focus:outline-none"
      placeholder="Search"
      readonly
    />
  </div>
</template>

<script setup>
function handleClick() {
  document.dispatchEvent(
    new KeyboardEvent('keydown', {
      key: '/',
    })
  )
}
</script>
