<template>
  <Tag :label="deliveryStatusOption?.label ?? 'No Status'" :color="color" :isSmall="isSmall" />
</template>

<script setup>
import { computed } from 'vue'
import { Tag } from '@aryeo/runway'

import ListingDeliveryStatusOptions from '@/Utils/Listings/ListingDeliveryStatusOptions'

const props = defineProps({
  deliveryStatus: {
    type: String,
    required: true,
  },
  isSmall: {
    type: Boolean,
    default: false,
  },
})

const deliveryStatusOption = computed(() => {
  return ListingDeliveryStatusOptions.find(
    (status) => status.value === props.deliveryStatus.toLowerCase()
  )
})

const color = computed(() => {
  return deliveryStatusOption.value?.color ?? 'secondary'
})
</script>
