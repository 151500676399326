import {
  AnnotationIcon,
  BellIcon,
  BriefcaseIcon,
  CreditCardIcon,
  CursorClickIcon,
  DownloadIcon,
  HomeIcon,
  LightningBoltIcon,
  LocationMarkerIcon,
  RefreshIcon,
  ScaleIcon,
  ShoppingCartIcon,
  TagIcon,
  TemplateIcon,
  UserCircleIcon,
  UserGroupIcon,
  VariableIcon,
  CollectionIcon,
} from '@heroicons/vue/outline'

import { useAuth } from '@/Hooks/Company/useAuth'
import { usePermissions } from '@/Hooks/Company/usePermissions'
import { useFeatureFlags } from '@/Hooks/Company/useFeatureFlags'

export default function useSettingsNavigation() {
  const { authOrganization, authCompanyUser } = useAuth()
  const { hasPermission } = usePermissions()
  const { hasFeatureFlag } = useFeatureFlags()

  return {
    overviewTitle: 'Settings Overview',
    overviewRouteName: 'web.company.settings-overview.show',
    sections: [
      {
        show: true,
        name: 'User Settings',
        description: 'Settings that relate to your individual user.',
        children: [
          {
            show: true,
            name: 'User Details',
            description: 'Update your user details such as login email and password.',
            routeIsInertia: true,
            targetRouteName: 'web.company.auth-company-user.show',
            icon: UserCircleIcon,
            routeNamesToCheckIfCurrent: ['web.company.auth-company-user.*'],
          },
          {
            show: true,
            disabled: !authOrganization.value.is_pro_account,
            name: 'Notification Preferences',
            description: 'Configure your notification preferences.',
            routeIsInertia: true,
            targetRouteName: 'web.company.notifications.preferences.edit',
            icon: BellIcon,
            routeNamesToCheckIfCurrent: ['web.company.notifications.preferences.edit'],
          },
        ],
      },
      {
        show: hasPermission('settings_manage'),
        name: 'Company Settings',
        description: 'Settings that relate to your entire company.',
        children: [
          {
            show: true,
            name: 'Company Details',
            description:
              "Update your company's branding name, logo, email, and other contact information.",
            routeIsInertia: true,
            targetRouteName: 'web.company.auth-company.edit',
            icon: UserGroupIcon,
            routeNamesToCheckIfCurrent: ['web.company.auth-company.*'],
          },
          {
            show: hasPermission('settings_manage'),
            disabled: !authOrganization.value.is_pro_account,
            name: 'Notifications',
            description:
              'Configure notification templates, and assign additional recipients to notifications.',
            routeIsInertia: true,
            targetRouteName: 'web.company.notifications.settings.index',
            icon: BellIcon,
            routeNamesToCheckIfCurrent: ['web.company.notifications.settings.*'],
          },
          {
            show: true,
            disabled: !authOrganization.value.is_pro_account,
            name: 'Downloads',
            description:
              'Adjust your file naming conventions and locking downloads before payment.',
            routeIsInertia: true,
            targetRouteName: 'web.company.downloads.settings.show',
            icon: DownloadIcon,
            routeNamesToCheckIfCurrent: ['web.company.downloads.settings.*'],
          },
          {
            show: true,
            disabled: !authOrganization.value.is_pro_account,
            name: 'Property Websites',
            description: 'Adjust your site default settings.',
            routeIsInertia: true,
            targetRouteName: 'web.company.property-websites.settings.show',
            icon: CursorClickIcon,
            routeNamesToCheckIfCurrent: ['web.company.property-websites.settings.*'],
          },
          {
            show: true,
            disabled: !authOrganization.value.is_pro_account,
            name: 'Addresses',
            description:
              'Adjust how addresses are displayed in the system and on property websites.',
            routeIsInertia: true,
            targetRouteName: 'web.company.addresses.settings.index',
            icon: LocationMarkerIcon,
            routeNamesToCheckIfCurrent: ['web.company.addresses.settings*'],
          },
          {
            show: true,
            disabled: !authOrganization.value.is_pro_account,
            name: 'Orders & Payments',
            description: 'Order and payment settings.',
            routeIsInertia: true,
            targetRouteName: 'web.company.payments.settings.show',
            icon: ScaleIcon,
            routeNamesToCheckIfCurrent: ['web.company.payments.settings.*'],
          },
          {
            show: true,
            disabled: !authOrganization.value.is_pro_account,
            name: 'Marketing',
            description: "Update your group's marketing settings and marketing material templates.",
            routeIsInertia: true,
            targetRouteName: 'web.company.marketing-settings.edit',
            icon: TemplateIcon,
            routeNamesToCheckIfCurrent: [
              'web.company.marketing-material-templates.*',
              'web.company.marketing-settings.*',
            ],
          },
          {
            show: hasPermission('settings_manage'),
            disabled: !authOrganization.value.is_pro_account,
            name: 'Listings',
            description: "Update your group's listing settings.",
            routeIsInertia: true,
            targetRouteName: 'web.company.default-listings.settings.show',
            icon: HomeIcon,
            routeNamesToCheckIfCurrent: ['web.company.default-listings.settings.*'],
          },
          {
            show: true,
            disabled: !authOrganization.value.is_pro_account,
            name: 'Legal',
            description: 'Terms of Service.',
            routeIsInertia: true,
            targetRouteName: 'web.company.legal.settings.show',
            icon: BriefcaseIcon,
            routeNamesToCheckIfCurrent: ['web.company.legal.settings.*'],
          },
          {
            show: hasPermission('settings_manage'),
            disabled: !authOrganization.value.is_pro_account,
            name: 'Tags',
            description: 'Manage the tags available for use in the system.',
            routeIsInertia: true,
            targetRouteName: 'web.company.tags.index',
            icon: TagIcon,
            routeNamesToCheckIfCurrent: [
              'web.company.tags.index',
              'web.company.tags.create',
              'web.company.tags.edit',
            ],
          },
          {
            show: true,
            disabled: !authOrganization.value.is_pro_account,
            name: 'Custom Fields',
            description: 'Update your custom fields.',
            routeIsInertia: true,
            targetRouteName: 'web.company.custom-fields.index',
            targetRouteAttributes: { custom_fieldable_type: 'company_team_member' },
            icon: AnnotationIcon,
            routeNamesToCheckIfCurrent: ['web.company.custom-fields.*'],
          },
          {
            show: true,
            name: 'Customer Teams',
            description: "Update settings for your Customer's Teams.",
            routeIsInertia: true,
            targetRouteName: 'web.company.settings.customer-teams.index',
            icon: CollectionIcon,
            routeNamesToCheckIfCurrent: ['web.company.settings.customer-teams.index'],
          },
        ],
      },
      {
        show: hasPermission('settings_manage'),
        name: 'Billing and Payment Settings',
        description: 'Settings that relate to your subscription, payments, and billing history.',
        children: [
          {
            show: true,
            disabled: !authOrganization.value.is_pro_account,
            name: 'Billing',
            description: 'Update your subscription or default payment methods.',
            routeIsInertia: true,
            targetRouteName: 'web.company.billing-settings.show',
            icon: CreditCardIcon,
            routeNamesToCheckIfCurrent: ['web.company.billing-settings.*'],
          },
          {
            show: true,
            disabled: !authOrganization.value.is_pro_account,
            name: 'Credits',
            description:
              'Purchase credits for your business, used for listing domains and some other add-on services.',
            routeIsInertia: true,
            targetRouteName: 'web.company.aryeo-credits.index',
            icon: ShoppingCartIcon,
            routeNamesToCheckIfCurrent: ['web.company.aryeo-credits.*'],
          },
          {
            show: true,
            disabled: !authOrganization.value.is_pro_account,
            name: 'Referral Program',
            description: 'Refer other creators to Aryeo and receive free bonus listings.',
            routeIsInertia: true,
            targetRouteName: 'web.company.referrals.index',
            icon: RefreshIcon,
            routeNamesToCheckIfCurrent: ['referrals.*', 'referrals.*'],
          },
        ],
      },
      {
        show: hasPermission('app_store_view_any'),
        name: 'Developer Settings',
        description:
          "Settings that relate to your group's integrations with the Aryeo API and other 3rd party services.",
        children: [
          {
            show: true,
            disabled: !authOrganization.value.is_pro_account,
            name: 'API Keys',
            description: 'Manage keys used for integrations and API requests.',
            routeIsInertia: true,
            targetRouteName: 'web.company.personal-access-tokens.index',
            icon: LightningBoltIcon,
            routeNamesToCheckIfCurrent: ['web.company.personal-access-tokens.*'],
          },
          {
            show: hasFeatureFlag('webhooks'),
            disabled: !authOrganization.value.is_pro_account,
            name: 'Webhook Subscriptions',
            description: 'Manage your webhooks subscriptions.',
            routeIsInertia: true,
            targetRouteName: 'web.company.webhook-subscriptions.index',
            icon: VariableIcon,
            routeNamesToCheckIfCurrent: ['web.company.webhook-subscriptions.*'],
          },
        ],
      },
    ],
  }
}
