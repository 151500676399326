<script setup>
import { useAuth } from '@/Hooks/Customer/useAuth.js'
import { computed } from 'vue'

import { Button, Tooltip } from '@aryeo/runway'

defineProps({
  link: {
    type: String,
    default: null,
  },
  isFullWidth: {
    type: Boolean,
    default: false,
  },
  iconName: {
    type: String,
    default: null,
  },
  text: {
    type: String,
    default: 'Place Order',
  },
})

const { authCustomerTeam, authCustomerTeamMembership, authCustomerOrganization } = useAuth()

const canPlaceOrder = computed(() => {
  return (
    authCustomerOrganization.value.is_pro_account &&
    !authCustomerTeamMembership.value.is_archived &&
    !authCustomerTeam.value.is_archived
  )
})

const tooltipText = computed(() => {
  if (canPlaceOrder.value) {
    return null
  }

  if (!authCustomerOrganization.value.is_pro_account) {
    return 'This feature is an Aryeo Pro exclusive.'
  }

  return 'Archived team members cannot place orders'
})
</script>

<template>
  <Tooltip placement="top" :text="tooltipText">
    <Button
      :class="{ 'w-full': isFullWidth }"
      color="primary"
      :iconName="iconName"
      :isDisabled="!canPlaceOrder"
      :link="canPlaceOrder ? route('web.customer.order-landing.show') : null"
      openInNewTab
      :text="text"
    />
  </Tooltip>
</template>
