export function capitalizeString(string) {
  return string[0].toUpperCase() + string.slice(1).toLowerCase()
}

export function pluralize(length, singular, plural) {
  return length === 1 ? singular : plural
}

export function stripHtmlFromRichtext(text) {
  if (typeof text !== 'string') return text

  return text.replace(/(<([^>]+)>)/gi, '')
}
