<template>
  <div v-if="optionGroupings.length > 0">
    <div class="flex divide-x divide-gray-100">
      <Stack
        :class="[
          'max-h-120 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4',
          activeOption?.type === 'COMPANY' && 'sm:h-120',
        ]"
        space="small"
      >
        <div class="-mx-2 text-sm text-gray-700">
          <div v-for="grouping in optionGroupings" :key="grouping.name" class="mb-4">
            <Typography variant="h4" as="h4" class="mb-2">{{ grouping.name }}</Typography>

            <CommandPaletteOption
              v-for="option in grouping.options"
              :key="JSON.stringify(option)"
              :option="option"
              :active="activeOption?.id === option.id"
            />
          </div>
        </div>
      </Stack>

      <CommandPalettePreview v-if="previewingOption" :option="previewingOption" class="w-1/3" />
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { Typography, Stack } from '@aryeo/runway'
import CommandPalettePreview from '@/Components/Shared/CommandPalette/CommandPalettePreview.vue'
import CommandPaletteOption from '@/Components/Shared/CommandPalette/CommandPaletteOption.vue'

const props = defineProps({
  optionGroupings: {
    type: Array,
    required: true,
  },
  activeOption: { type: Object },
})

const previewingOption = ref(null)

watch(
  () => props.activeOption,
  (option) => {
    if (option !== null) {
      previewingOption.value = option
    }
  }
)
</script>
