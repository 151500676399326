<template>
  <Menu as="div" class="relative inline-block my-auto text-left">
    <div>
      <MenuButton
        class="flex items-center justify-center h-10 px-3 text-sm rounded-md bg-header-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-light focus:ring-primary-light lg:rounded-md lg:hover:bg-header"
      >
        <span class="block mr-3 text-sm font-medium text-header-font truncate"
          ><span class="sr-only">Open user menu for </span>{{ authCustomerUser.first_name }}
          {{ authCustomerUser.last_name }}</span
        >
        <Avatar
          :firstName="authCustomerUser.first_name"
          :lastName="authCustomerUser.last_name"
          :image="authCustomerUser.avatar_url"
          size="xsmall"
        />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="scale-95 opacity-0"
      enter-to-class="scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="scale-100 opacity-100"
      leave-to-class="scale-95 opacity-0"
    >
      <MenuItems
        class="absolute right-0 z-30 w-56 mt-1 origin-top-right bg-white divide-y rounded-md shadow-lg divide-header-off-white-dark ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <aryeo-link
              :href="route('web.customer.auth-customer-user.show')"
              :class="[
                active ? 'bg-header-off-white text-gray-900' : 'text-gray-700',
                'group flex items-center px-4 py-2 text-sm',
              ]"
            >
              <UserCircleIcon
                class="w-5 h-5 mr-3 text-gray-500 group-hover:text-gray-700"
                aria-hidden="true"
              />
              Edit User
            </aryeo-link>
          </MenuItem>
          <MenuItem v-slot="{ active }" v-if="authCustomerOrganization.is_pro_account">
            <aryeo-link
              :href="route('web.customer.notifications.preferences.edit')"
              :class="[
                active ? 'bg-header-off-white text-gray-900' : 'text-gray-700',
                'group flex items-center px-4 py-2 text-sm',
              ]"
            >
              <BellIcon
                class="w-5 h-5 mr-3 text-gray-500 group-hover:text-gray-700"
                aria-hidden="true"
              />
              Notification Preferences
            </aryeo-link>
          </MenuItem>
        </div>
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <form method="post" :action="route('web.customer.login.destroy')">
              <CsrfToken />
              <button
                type="submit"
                :class="[
                  active ? 'bg-header-off-white text-gray-900' : 'text-gray-700',
                  'group flex items-center px-4 py-2 text-sm w-full',
                ]"
              >
                <LogoutIcon
                  class="w-5 h-5 mr-3 text-gray-500 group-hover:text-gray-700"
                  aria-hidden="true"
                />
                Logout
              </button>
            </form>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import { Avatar } from '@aryeo/runway'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { BellIcon, UserCircleIcon, LogoutIcon } from '@heroicons/vue/solid'

import { useAuth } from '@/Hooks/Customer/useAuth'

import CsrfToken from '@/Components/Shared/CsrfToken.vue'

const { authCustomerUser, authCustomerOrganization } = useAuth()
</script>
