const PaymentStatusOptions = [
  {
    label: 'Paid',
    value: 'paid',
    color: 'success-lightest',
  },
  {
    label: 'Unpaid',
    value: 'unpaid',
    color: 'danger-lightest',
  },
  {
    label: 'Partially Paid',
    value: 'partially_paid',
    color: 'warning-lightest',
  },
  {
    label: 'Refunded',
    value: 'refunded',
    color: 'info-light',
  },
  {
    label: 'Partially Refunded',
    value: 'partially_refunded',
    color: 'info-light',
  },
]

export default PaymentStatusOptions
