import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'

export function useCsrfToken() {
  const page = usePage()

  const csrfToken = computed(() => page.props.csrf_token ?? page.props.value.csrfToken)

  return {
    csrfToken,
  }
}
