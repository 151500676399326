<template>
  <div class="p-4 text-left rounded-md" :class="alertSettings().backgroundColor">
    <div class="flex">
      <div class="shrink-0">
        <component
          v-if="!hideIcon"
          :is="alertSettings().icon"
          class="w-5 h-5"
          :class="alertSettings().iconColor"
          aria-hidden="true"
        />
      </div>
      <div class="w-full" :class="{ 'ml-3': !hideIcon }">
        <Stack space="xsmall">
          <h3 v-if="title" class="text-sm font-medium" :class="alertSettings().titleTextColor">
            {{ title }}
          </h3>
          <div
            v-if="description"
            class="text-sm text-balance"
            :class="alertSettings().descriptionTextColor"
          >
            {{ description }}
          </div>
          <slot />
        </Stack>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Stack } from '@aryeo/runway'
import { InformationCircleIcon, CheckCircleIcon, ExclamationIcon } from '@heroicons/vue/solid'

const props = defineProps({
  title: {
    type: String,
    required: false,
  },
  description: {
    type: String,
    required: false,
  },
  type: {
    type: String,
    default: 'info',
  },
  hideIcon: {
    type: Boolean,
    default: false,
  },
})

const alertSettings = () => {
  let backgroundColor = 'bg-blue-50'
  let icon = InformationCircleIcon
  let iconColor = 'text-blue-400'
  let titleTextColor = 'text-blue-800'
  let descriptionTextColor = 'text-blue-700'

  switch (props.type) {
    case 'success':
      backgroundColor = 'bg-emerald-50'
      icon = CheckCircleIcon
      iconColor = 'text-emerald-400'
      titleTextColor = 'text-emerald-800'
      descriptionTextColor = 'text-emerald-700'
      break
    case 'danger':
      backgroundColor = 'bg-red-50'
      icon = ExclamationIcon
      iconColor = 'text-red-400'
      titleTextColor = 'text-red-800'
      descriptionTextColor = 'text-red-700'
      break
    case 'warning':
      backgroundColor = 'bg-amber-50'
      icon = ExclamationIcon
      iconColor = 'text-amber-400'
      titleTextColor = 'text-amber-800'
      descriptionTextColor = 'text-amber-700'
      break
    case 'disabled':
      backgroundColor = 'bg-gray-50'
      icon = InformationCircleIcon
      iconColor = 'text-gray-400'
      titleTextColor = 'text-gray-800'
      descriptionTextColor = 'text-gray-700'
      break
    default:
      break
  }

  return {
    backgroundColor,
    icon,
    iconColor,
    titleTextColor,
    descriptionTextColor,
  }
}
</script>
