<template>
  <header class="relative z-20 flex shrink-0 h-14">
    <div
      class="content-center justify-center hidden w-64 shrink-0 bg-header-darker md:flex h-14 md:px-3"
    >
      <div class="relative inline-block w-full my-auto text-left">
        <div
          class="flex items-center justify-between w-full h-10 px-3 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light lg:rounded-md focus:ring-offset-primary-light text-header-font"
        >
          <span class="text-sm font-medium truncate">
            {{ authCustomerTeam.company.name }}
          </span>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-end flex-1 px-4 space-x-6 bg-header-dark h-14 md:px-6">
      <div class="items-center hidden md:flex">
        <CustomerUserDropdown />
      </div>
    </div>
    <div class="flex content-center justify-center px-4 shrink-0 bg-header-darker md:hidden h-14">
      <button
        class="block text-header-lighter hover:text-header-off-white-dark focus:outline-none focus:text-header-off-white-dark"
        @click="toggleSidebarOpen"
      >
        <svg
          viewBox="0 0 24 24"
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M3 12h18M3 6h18M3 18h18" />
        </svg>
      </button>
    </div>
  </header>
</template>

<script setup>
import { inject } from 'vue'
import CustomerUserDropdown from '@/Components/Customer/Header/CustomerUserDropdown.vue'
import { useAuth } from '@/Hooks/Customer/useAuth'

const toggleSidebarOpen = inject('toggleSidebarOpen')

const { authCustomerTeam } = useAuth()
</script>
