<template>
  <MobileSlideoverWrapper>
    <TransitionRoot
      :show="secondaryColumnOpen"
      appear
      enter="transition ease-in-out duration-300"
      enter-from="translate-x-full"
      enter-to="translate-x-0"
    >
      <SecondaryColumn
        :navigation="secondaryNavigationForCurrentUrl()"
        :back-action="toggleSecondaryColumnOpen"
      />
    </TransitionRoot>
    <TransitionRoot
      :show="!secondaryColumnOpen"
      :appear="secondaryColumnOpen"
      enter="transition ease-in-out duration-300"
      enter-from="-translate-x-full"
      enter-to="translate-x-0"
    >
      <PrimaryColumn />
    </TransitionRoot>
  </MobileSlideoverWrapper>

  <template v-if="renderDesktopSidebar">
    <!-- Single static sidebar for mid-sized desktop -->
    <div class="hidden md:flex md:shrink-0">
      <div class="flex flex-col w-64">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex flex-col flex-1 h-0 bg-white border-r border-gray-200">
          <div class="flex flex-col flex-1 overflow-y-auto">
            <TransitionRoot
              :show="secondaryColumnOpen"
              appear
              enter="transition ease-in-out duration-300"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
            >
              <SecondaryColumn
                :navigation="secondaryNavigationForCurrentUrl()"
                :back-action="toggleSecondaryColumnOpen"
              />
            </TransitionRoot>
            <!--The TransitionRoot below uses the following logic to determine whether the
            transition appears on initial mount https://headlessui.dev/vue/transition#transitioning-on-initial-mount.
            I haven't been able to investigate the behavior enough to truly understand whats going on,
            but switching between two inertia pages where the secondaryColumnOpen changes, therefore
            changing which column is rendered, I could not get the primary column transition to fire
            without the appear attribute (component is re-mounting?).
            This is undesirable though, as then the column will transition on every page load
            changing between non-inertia to inertia pages.
            However, by chance I discovered that predicating appear based on secondaryColumnOpen
            (:appear="secondaryColumnOpen") achieved the behavior we want. The primary column only
            transitions in as the scondary column transitions out. Why? Not certain... could be a race
            condition of sorts between how headless handles checking these attributes? -->
            <TransitionRoot
              :show="!secondaryColumnOpen"
              :appear="secondaryColumnOpen"
              enter="transition ease-in-out duration-300"
              enter-from="-translate-x-full"
              enter-to="translate-x-0"
            >
              <PrimaryColumn />
            </TransitionRoot>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script setup>
import { ref, watch, provide } from 'vue'
import { TransitionRoot } from '@headlessui/vue'
import { usePage } from '@inertiajs/vue3'

import { useSecondaryNavigation } from '@/Hooks/Company/Navigation/useSecondaryNavigation'

import MobileSlideoverWrapper from '@/Components/Shared/Sidebar/MobileSlideoverWrapper.vue'
import PrimaryColumn from '@/Components/Company/Sidebar/PrimaryColumn.vue'
import SecondaryColumn from '@/Components/Company/Sidebar/SecondaryColumn.vue'

defineProps({
  renderDesktopSidebar: {
    type: Boolean,
    default: true,
  },
})

const page = usePage()

const { secondaryNavigationForCurrentUrl } = useSecondaryNavigation()

const secondaryColumnOpen = ref(!!secondaryNavigationForCurrentUrl())

const toggleSecondaryColumnOpen = () => {
  secondaryColumnOpen.value = !secondaryColumnOpen.value
}

const resetSecondaryColumnOpen = () => {
  secondaryColumnOpen.value = !!secondaryNavigationForCurrentUrl()
}

watch(page, () => {
  resetSecondaryColumnOpen()
})

provide('resetSecondaryColumnOpen', resetSecondaryColumnOpen)
</script>
