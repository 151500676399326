import { ref, watch } from 'vue'
import { usePage } from '@inertiajs/vue3'

export function useCurrentRoute(routeNames, params = {}) {
  const page = usePage()

  const currentUrlMatchesRouteNames = (routeNamesToCheck) => {
    return []
      .concat(routeNamesToCheck)
      .filter((routeNameToCheck) => routeNameToCheck)
      .some((routeNameToCheck) => route().current(routeNameToCheck, params))
  }

  const isCurrentForRouteNames = ref(currentUrlMatchesRouteNames(routeNames))

  watch(page, () => {
    isCurrentForRouteNames.value = currentUrlMatchesRouteNames(routeNames)
  })

  return {
    currentUrlMatchesRouteNames,
    isCurrentForRouteNames,
  }
}
