<template>
  <div
    class="flex gap-4 max-sm:flex-col items-center justify-between text-center bg-warning-lightest text-warning-darkest px-3 py-2 font-[sans-serif]"
  >
    <div>
      <p class="text-base">You are currently in impersonation mode.</p>
    </div>

    <div class="flex gap-2">
      <Button
        class="border-warning-dark [&>span]:!text-warning-darkest [&_svg]:!text-warning-darkest"
        color="warning"
        iconName="signOut"
        isSecondary
        isSmall
        :link="route('nova.pages.index', { resource: 'company-team-members' })"
        openInNewTab
        text="Switch"
      />
      <Button
        class="border-warning-dark [&>span]:!text-warning-darkest [&_svg]:!text-warning-darkest"
        color="warning"
        isSecondary
        isSmall
        @click="stopImpersonating"
        text="Exit"
        iconName="timesCircle"
      />
    </div>
  </div>
</template>

<script setup>
import { useAuth } from '@/Hooks/Company/useAuth'
import { Button } from '@aryeo/runway'

const { stopImpersonating } = useAuth()
</script>
