import {
  ClockIcon,
  CalendarIcon,
  GlobeIcon,
  LocationMarkerIcon,
  SunIcon,
  ViewListIcon,
} from '@heroicons/vue/outline'

import { usePermissions } from '@/Hooks/Company/usePermissions'

export default function useAvailabilityNavigation() {
  const { hasPermission } = usePermissions()

  return {
    overviewTitle: 'Availability Overview',
    overviewRouteName: 'web.company.availability.overview.show',
    sections: [
      {
        show: true,
        name: 'When',
        description: 'When is your group available for services?',
        targetRouteName: null,
        children: [
          {
            show: true,
            name: 'Business Hours',
            description:
              'Set default business hours for your group and (optional) manage individual user overrides.',
            routeIsInertia: true,
            targetRouteName: 'web.company.business-hours.index',
            icon: ClockIcon,
            routeNamesToCheckIfCurrent: ['web.company.business-hours.*'],
          },
          {
            show: true,
            name: 'Twilight Availability',
            description:
              'Set days when users should be available for twilight shoots outside of normal business hours.',
            routeIsInertia: true,
            targetRouteName: 'web.company.twilight-availability.index',
            icon: SunIcon,
            routeNamesToCheckIfCurrent: ['web.company.twilight-availability.*'],
          },
          {
            show: true,
            name: 'Calendar Sync',
            description:
              'Sync your availability with an external google or outlook calendar and push appointments as events once scheduled in Aryeo.',
            routeIsInertia: true,
            targetRouteName: 'web.company.calendar-syncs.index',
            icon: CalendarIcon,
            routeNamesToCheckIfCurrent: ['web.company.calendar-syncs.*'],
          },
        ],
      },
      {
        show: true,
        name: 'Where',
        description: 'Where does your group provide services?',
        targetRouteName: 'web.company.availability.overview.show',
        children: [
          {
            show: true,
            name: 'Home Bases',
            description:
              'Set the home base of your users - used (when applicable) to calculate how far they need to travel to events.',
            routeIsInertia: true,
            targetRouteName: 'web.company.home-bases.index',
            icon: LocationMarkerIcon,
            routeNamesToCheckIfCurrent: ['web.company.home-bases.*'],
          },
          {
            show: hasPermission('territory_view_any'),
            name: 'Territories',
            description:
              "Manage your group's service areas by creating territories and assigning users. Only products these users offer are available in that territory.",
            routeIsInertia: true,
            targetRouteName: 'web.company.territories.index',
            icon: GlobeIcon,
            routeNamesToCheckIfCurrent: ['web.company.territories.*'],
          },
        ],
      },
      {
        show: true,
        name: 'Booking Options',
        description:
          'Based on when and where your users are available, what times should customers see when booking? And should they be able to reschedule or cancel after booking?',
        targetRouteName: 'web.company.availability.overview.show',
        children: [
          {
            show: true,
            name: 'Default Booking Limits',
            description:
              "Set default booking limits for all service provider team members. Booking limits set within individual team member's Booking Limit Overrides settings will take priority over these settings.",
            routeIsInertia: true,
            targetRouteName: 'web.company.booking-limits.index',
            icon: ClockIcon,
            routeNamesToCheckIfCurrent: ['web.company.booking-limits.*'],
          },
          {
            show: hasPermission('appointments_manage'),
            name: 'Appointment Settings',
            description: 'Adjust whether appointments can be rescheduled or canceled.',
            routeIsInertia: true,
            targetRouteName: 'web.company.appointments.settings.index',
            icon: LocationMarkerIcon,
            routeNamesToCheckIfCurrent: ['web.company.appointments.settings.*'],
          },
          {
            show: hasPermission('availability_view_any'),
            name: 'Assignment Priority',
            description:
              'Set the assignment priority order of your team members. This order will be used when your order form is set to use the Priority List user assignment strategy.',
            routeIsInertia: true,
            targetRouteName: 'web.company.scheduling.priority.index',
            icon: ViewListIcon,
            routeNamesToCheckIfCurrent: ['web.company.scheduling.priority.index'],
          },
          {
            show: hasPermission('availability_view_any'),
            name: 'Default Scheduling Settings',
            description:
              'Set your default scheduling settings that are used for new order forms and for orders that are created without an order form.',
            routeIsInertia: true,
            targetRouteName: 'web.company.scheduling-settings.edit',
            icon: ClockIcon,
            routeNamesToCheckIfCurrent: ['web.company.scheduling-settings.edit'],
          },
        ],
      },
    ],
  }
}
