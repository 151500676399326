import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'

export function paymentsEnabled() {
  const page = usePage()
  const tenant = computed(() => page.props?.tenant)
  return tenant.value?.company.are_payments_enabled
}

export function pricingEnabled() {
  const page = usePage()
  const tenant = computed(() => page.props?.tenant)
  return tenant.value?.company.is_pricing_enabled
}

export function useCompanySettings() {
  const page = usePage()

  return {
    paymentsEnabled: computed(() => page.props?.tenant?.company.are_payments_enabled),
    pricingEnabled: computed(() => page.props?.tenant?.company.is_pricing_enabled),
  }
}
