import { h } from 'vue'
import { Link, usePage } from '@inertiajs/vue3'
import { setPage, getPageCache, addPageCache } from '@/Utils/InertiaUtil'

export default {
  name: 'Link',
  props: {
    ghost: {
      type: Boolean,
      required: false,
    },
    preload: {
      type: String,
      default: null,
    },
    preloadProps: {
      type: Object,
      default: () => ({}),
    },
    href: {
      type: String,
      default: null,
    },
  },
  setup(props, { slots, attrs }) {
    const page = usePage()

    function setPreloadPage(pageComponent, pageProps) {
      const currentPageProps = JSON.parse(JSON.stringify(page.props))

      addPageCache(page.component, currentPageProps)

      setPage({
        component: pageComponent,
        props: {
          ...currentPageProps,
          ...getPageCache(pageComponent),
          ...JSON.parse(
            JSON.stringify({
              ...pageProps,
            })
          ),
        },
        url: props.href,
        version: page.version,
      })
    }

    return (props) => {
      return h(
        Link,
        {
          ...attrs,
          href: props.href || undefined,
          onClick: (event) => {
            if (props.ghost) {
              // TODO: This isn't used right now but it should be used in the future.
              // Ideally we can develop a set of generic ghost pages and change this
              // `ghost` prop to be a string with the value of the desired ghost page.
              return setPreloadPage('Ghost/GhostPage', {
                meta: {
                  renderDefaultPageWrapper: false,
                },
              })
            }
            if (props.preload) {
              return setPreloadPage(props.preload, props.preloadProps)
            }
          },
        },
        slots
      )
    }
  },
}
