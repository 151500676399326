<script setup>
import { computed, ref } from 'vue'
import { router, usePage } from '@inertiajs/vue3'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import {
  Avatar,
  Divider,
  FormSearchInput,
  Icon,
  Stack,
  Tooltip,
  Typography,
  Tag,
  Box,
} from '@aryeo/runway'

import { useAuth } from '@/Hooks/Customer/useAuth.js'
import CustomerTeamUpdateDefaultMembershipModal from '@/Components/Customer/CustomerTeams/CustomerTeamUpdateDefaultMembershipModal.vue'

defineProps({
  darkMode: { type: Boolean, default: true },
})

const page = usePage()

const { authCustomerTeamMemberships, authCustomerTeamMembership } = useAuth()

const selectDefaultMembershipModalIsOpen = ref(false)
const selectDefaultMembershipIsLoading = ref(false)
const searchTerm = ref('')

const filteredCustomerTeamMemberships = computed(() =>
  authCustomerTeamMemberships.value.data.filter((membership) =>
    membership.customer_team.name.toLowerCase().includes(searchTerm.value.toLowerCase())
  )
)

const showChangeDefault = computed(() => filteredCustomerTeamMemberships.value.length > 1)

const animateTeamSwitcherTooltip = ref(true)
const showTeamSwitcherTooltip = ref(page.props.newCustomerTeam ?? false)

setTimeout(() => {
  animateTeamSwitcherTooltip.value = false
}, 5000)

const isSelected = (membership) => {
  return authCustomerTeamMembership.value.id === membership.id
}

const handleSearch = ({ key, value }) => {
  searchTerm.value = value
}

const handleSelectCustomerTeamMembership = async (membershipId) => {
  router.put(
    route('web.customer.customer-team-memberships.selected.update', membershipId),
    {},
    {
      preserveState: false,
      onSuccess: () => {
        searchTerm.value = ''
      },
    }
  )
}

const handleSelectDefaultCustomerTeamMembership = (membershipId) => {
  selectDefaultMembershipIsLoading.value = true

  router.put(
    route('web.customer.customer-team-memberships.default.update', membershipId),
    {},
    {
      onSuccess: () => {
        selectDefaultMembershipModalIsOpen.value = false
        selectDefaultMembershipIsLoading.value = false
      },
    }
  )
}

const listingsCountForMembership = (membership) =>
  membership.role === 'admin'
    ? listingsCount(membership.customer_team.listings_count)
    : listingsCount(membership.listings_count)

const listingsCount = (count) => {
  if (count > 1) {
    return `${count.toLocaleString()} listings`
  } else if (count === 1) {
    return '1 listing'
  } else {
    return 'No listings'
  }
}

const canCreateTeamsLegend = computed(() => {
  return page.props?.auth.customer_team.company.customers_can_create_teams
    ? 'Create or join a team'
    : 'Join Team'
})
</script>

<template>
  <Menu as="div" class="relative inline-block w-full my-auto text-left">
    <h3 class="mb-2 text-xs font-semibold tracking-wider text-primary uppercase">Current Team</h3>

    <div>
      <MenuButton
        id="customer-team-menu-button"
        class="flex items-center justify-between w-full h-14 px-3 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light lg:rounded-md focus:ring-offset-primary-light"
        :class="
          darkMode
            ? 'text-header-font lg:hover:bg-header-dark'
            : 'text-header-dark hover:text-header-darkest hover:bg-header-off-white border border-secondary'
        "
        @click="showTeamSwitcherTooltip = false"
      >
        <div class="text-left truncate">
          <span class="font-medium text-gray-900">
            <span class="sr-only">Open customer team menu</span>
            {{ authCustomerTeamMembership.customer_team.name }}
          </span>
          <p class="text-xs text-gray-500">
            {{ `Team ${authCustomerTeamMembership.role}` }}
          </p>
        </div>
        <ChevronDownIcon class="block w-5 h-5 ml-1 shrink-0" aria-hidden="true" />

        <transition
          leave-active-class="transition duration-150"
          leave-from-class="scale-100 opacity-100"
          leave-to-class="scale-90 opacity-0"
        >
          <Tooltip
            v-if="showTeamSwitcherTooltip"
            placement="bottom"
            :contentInteractive="true"
            trigger="mouseenter focus"
            class="absolute -right-1"
          >
            <template #default>
              <div @mouseenter="animateTeamSwitcherTooltip = false" class="relative flex size-4">
                <Icon name="infoCircle" size="small" color="success" />
                <span
                  v-if="animateTeamSwitcherTooltip"
                  class="hidden motion-safe:inline-flex animate-ping absolute h-full w-full rounded-full bg-emerald-400 opacity-60"
                ></span>
              </div>
            </template>
            <template #content>
              <Stack class="p-2" space="xsmall">
                <Typography as="p"> Switch between team spaces here. </Typography>
              </Stack>
            </template>
          </Tooltip>
        </transition>
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="scale-95 opacity-0"
      enter-to-class="scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="scale-100 opacity-100"
      leave-to-class="scale-95 opacity-0"
    >
      <MenuItems
        class="absolute max-h-screen-75 overflow-y-auto left-0 z-30 w-full mt-1 px-2 py-3 origin-top-left bg-white rounded-md shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <MenuItem v-if="authCustomerTeamMemberships.data.length >= 10">
          <FormSearchInput
            :value="searchTerm"
            hasNoResultsMessage="No customer teams were found for your search."
            placeholderText="Search by team name..."
            @update:modelValue="handleSearch"
          />
        </MenuItem>
        <MenuItem
          v-for="customerTeamMembership in filteredCustomerTeamMemberships"
          :key="customerTeamMembership.id"
          class="rounded-md cursor-pointer"
          :class="[
            isSelected(customerTeamMembership)
              ? 'bg-primary-lightest border-primary border-[3px]'
              : 'hover:bg-background-light border-background-light border-[2px]',
            'mb-3',
          ]"
          @click.prevent="handleSelectCustomerTeamMembership(customerTeamMembership)"
        >
          <div class="group text-sm">
            <Stack space="xxsmall">
              <Tooltip
                v-if="customerTeamMembership.customer_team.is_archived"
                placement="right"
                text="This team has been archived. You can still access your orders, but to place additional orders and manage future listings, please select another team in this menu or create a new one."
              >
                <Box
                  borderWidth="xsmall"
                  borderRadius="medium"
                  borderColor="border-lightest"
                  margin="xxsmall"
                  padding="xxsmall"
                  paddingX="small"
                  backgroundColor="background"
                >
                  <div class="flex flex-row items-center justify-between">
                    <Typography variant="label-small" color="subtle">Archived Team</Typography>
                    <Icon name="infoSquare" size="small" color="subtle" />
                  </div>
                </Box>
              </Tooltip>
              <Tooltip
                v-else-if="customerTeamMembership.is_archived"
                placement="right"
                text="You are an archived member of this team. You can still access your orders, but to place additional orders and manage future listings, please select another team in this menu or create a new one."
              >
                <Box
                  borderWidth="xsmall"
                  borderRadius="medium"
                  borderColor="border-lightest"
                  margin="xxsmall"
                  padding="xxsmall"
                  paddingX="small"
                  backgroundColor="background"
                >
                  <div class="flex flex-row items-center justify-between">
                    <Typography variant="label-small" color="subtle">Archived Member</Typography>
                    <Icon name="infoSquare" size="small" color="subtle" />
                  </div>
                </Box>
              </Tooltip>

              <Tooltip
                v-else-if="!customerTeamMembership.invitation_accepted_at"
                placement="right"
                text="You've been invited to this team. Click to accept or decline your invite."
              >
                <Box
                  borderWidth="xsmall"
                  borderRadius="medium"
                  borderColor="border-lightest"
                  margin="xxsmall"
                  padding="xxsmall"
                  paddingX="small"
                  backgroundColor="background"
                >
                  <div class="flex flex-row items-center justify-between">
                    <Typography variant="label-small" color="subtle">Invitation Pending</Typography>
                    <Icon name="infoSquare" size="small" color="subtle" />
                  </div>
                </Box>
              </Tooltip>

              <div class="flex flex-row justify-between w-full p-3 cursor-pointer">
                <Stack space="xsmall">
                  <div class="inline-flex" v-if="customerTeamMembership.is_default">
                    <Tag color="primary-light" label="Default" isSmall />
                  </div>
                  <p class="text-gray-900 text-wrap">
                    {{ customerTeamMembership.customer_team.name }}
                  </p>
                  <p class="text-xs text-gray-500">
                    {{ `Team ${customerTeamMembership.role}` }}
                  </p>
                  <p class="text-xs text-gray-500">
                    {{ listingsCountForMembership(customerTeamMembership) }}
                  </p>
                </Stack>
                <Avatar
                  class="shrink-0 ml-2"
                  :image="customerTeamMembership.customer_team.logo_url"
                  :firstName="customerTeamMembership.customer_team.name"
                  borderRadius="large"
                />
              </div>
            </Stack>
          </div>
        </MenuItem>
        <Divider class="my-3" />
        <div class="group flex flex-col text-sm">
          <aryeo-link
            :href="route('web.customer.customer-teams.create-or-join')"
            class="text-primary hover:bg-gray-100 p-3"
          >
            {{ canCreateTeamsLegend }}
          </aryeo-link>
          <button
            v-if="showChangeDefault"
            class="text-gray-500 text-left p-3 hover:bg-gray-100 rounded-b-md"
            @click.prevent="selectDefaultMembershipModalIsOpen = true"
          >
            Change default
          </button>
        </div>
      </MenuItems>
    </transition>
  </Menu>

  <CustomerTeamUpdateDefaultMembershipModal
    v-if="showChangeDefault"
    :customerTeamMemberships="authCustomerTeamMemberships.data"
    :isOpen="selectDefaultMembershipModalIsOpen"
    @onClose="selectDefaultMembershipModalIsOpen = false"
    @onConfirm="handleSelectDefaultCustomerTeamMembership"
  />
</template>
