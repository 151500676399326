import useCustomerNavigation from '@/Hooks/Company/Navigation/useCustomerNavigation.js'
import useProductNavigation from '@/Hooks/Company/Navigation/useProductNavigation.js'
import useAvailabilityNavigation from '@/Hooks/Company/Navigation/useAvailabilityNavigation.js'
import useSettingsNavigation from '@/Hooks/Company/Navigation/useSettingsNavigation.js'
import useAppStoreNavigation from '@/Hooks/Company/Navigation/useAppStoreNavigation.js'
import useCustomerPortalNavigation from '@/Hooks/Company/Navigation/useCustomerPortalNavigation.js'
import useZillowMediaExclusivesNavigation from '@/Hooks/Company/Navigation/useZillowMediaExclusivesNavigation.js'

import { useCurrentRoute } from '@/Hooks/Shared/useCurrentRoute.js'

export function useSecondaryNavigation() {
  const { currentUrlMatchesRouteNames } = useCurrentRoute()

  const currentUrlMatchesOverviewRouteNameOfNavigation = (navigation) => {
    return currentUrlMatchesRouteNames(navigation.overviewRouteName)
  }

  const currentUrlMatchesCurrentRouteNamesOfNavigation = (navigation) => {
    return navigation.sections
      .flatMap((navigationSection) => navigationSection.children)
      .flatMap((navigationItem) => navigationItem.routeNamesToCheckIfCurrent)
      .some((routeName) => currentUrlMatchesRouteNames(routeName))
  }

  const secondaryNavigationForCurrentUrl = () => {
    return [
      useCustomerNavigation(),
      useProductNavigation(),
      useAvailabilityNavigation(),
      useAppStoreNavigation(),
      useSettingsNavigation(),
      useCustomerPortalNavigation(),
      useZillowMediaExclusivesNavigation(),
    ].find(
      (navigation) =>
        currentUrlMatchesOverviewRouteNameOfNavigation(navigation) ||
        currentUrlMatchesCurrentRouteNamesOfNavigation(navigation)
    )
  }

  return {
    secondaryNavigationForCurrentUrl,
  }
}
