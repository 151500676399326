import { useAuth } from '@/Hooks/Company/useAuth'
import { DeviceMobileIcon, GlobeAltIcon } from '@heroicons/vue/outline'

export default function useCustomerPortalNavigation() {
  const { authOrganization } = useAuth()

  return {
    overviewTitle: 'Customer Portal',
    overviewRouteName: 'web.company.customer-portal.overview.show',
    sections: [
      {
        show: true,
        name: 'Customer Portal',
        description:
          'Provide your customers with a branded experience to streamline their booking process.',
        children: [
          {
            show: true,
            name: 'Website Settings',
            description:
              'A branded site allowing your customers to access their historical listings and place new orders.',
            routeIsInertia: true,
            targetRouteName: 'web.company.customer-portal.website.show',
            icon: GlobeAltIcon,
            routeNamesToCheckIfCurrent: [
              'web.company.customer-portal.website.*',
              'web.company.custom-domains.*',
            ],
            badge: 'New!',
          },
          {
            show: authOrganization.value.feature_data.limits.max_customer_portal_mobile_apps > 0,
            name: 'Mobile App Settings',
            description: 'A branded mobile app for your business available on iOS and Android.',
            routeIsInertia: true,
            targetRouteName: 'web.company.customer-portal.app.dashboard.show',
            icon: DeviceMobileIcon,
            routeNamesToCheckIfCurrent: ['web.company.customer-portal.app.*'],
          },
        ],
      },
    ],
  }
}
