import { router, usePage } from '@inertiajs/vue3'
import { computed, ref } from 'vue'
import { useCsrfToken } from '@/Hooks/Shared/useCsrfToken.js'
import axios from 'axios'

export function useAuth() {
  const { csrfToken } = useCsrfToken()
  const page = usePage()

  /*
   * Primary Auth Entities
   */
  const authCompanyUser = computed(() => page.props.auth?.company_user)
  const authCompanyTeamMember = computed(() => page.props.auth?.company_team_member)
  const authCompanyTeamMembers = computed(() => page.props.auth?.company_team_members)
  const authCompany = computed(() => page.props.auth?.company)
  const authOrganization = computed(() => page.props.auth?.organization)
  const isAryeoLiteUser = computed(() => !page.props.auth?.organization?.is_pro_account ?? false)
  const isCompanyUser = computed(() => !!authCompanyUser.value)
  const impersonation = computed(() => page.props.auth?.impersonation)

  /*
   * Additional attributes required for display in layout sidebar or header
   */
  const integrationRequiringAuthorization = computed(
    () => page.props.auth?.integration_requiring_authorization ?? false
  )

  const integrationRequiringConfigurationUpdate = computed(
    () => page.props.auth?.integration_requiring_configuration_update ?? false
  )

  const integrationExpiringSoon = computed(
    () => page.props.auth?.integration_expiring_soon ?? false
  )

  const needsHomeBase = computed(() => page.props.auth?.needs_home_base)

  const pendingMediaRequests = computed(() => page.props.auth?.pending_media_requests)

  const requiresAryeoLiteTermsAcceptance = computed(
    () =>
      !authOrganization?.value?.is_pro_account &&
      !authOrganization?.value?.aryeo_lite_terms_and_conditions_accepted
  )

  const portalAppConfigRequiringAction = computed(
    () => page.props.auth?.portal_app_config_requiring_action ?? false
  )

  const organizationNeedsBillingTaxDetails = computed(
    () => page.props.auth?.organization_needs_billing_tax_details
  )

  const otherTeamMemberNeedsHomeBase = computed(
    () => page.props.auth?.other_team_member_needs_home_base
  )

  const startImpersonating = (impersonate) => {
    axios
      .create({
        withCredentials: true,
        headers: {
          'X-CSRF-TOKEN': csrfToken.value,
        },
      })
      .post(route('nova.api.start-nova-impersonation'), {
        resource: impersonate.resource,
        resourceId: impersonate.resourceId,
        redirect: impersonate.redirect ?? null,
        company: impersonate.companyId ?? null,
      })
      .then((response) => {
        window.location.href = response.data.redirect
      })
  }

  const stopImpersonating = () => {
    window.dispatchEvent(new Event('stoppingImpersonation'))

    axios
      .create({
        withCredentials: true,
        headers: {
          'X-CSRF-TOKEN': csrfToken.value,
        },
      })
      .delete(route('nova.api.stop-nova-impersonation'), {
        _token: csrfToken.value,
        _method: 'DELETE',
      })
      .then((response) => (window.location.href = response.data.redirect))
  }

  return {
    authCompanyUser,
    authCompanyTeamMember,
    authCompanyTeamMembers,
    authCompany,
    authOrganization,
    isAryeoLiteUser,
    isCompanyUser,
    integrationRequiringAuthorization,
    integrationRequiringConfigurationUpdate,
    integrationExpiringSoon,
    needsHomeBase,
    pendingMediaRequests,
    portalAppConfigRequiringAction,
    otherTeamMemberNeedsHomeBase,
    organizationNeedsBillingTaxDetails,
    impersonation,
    startImpersonating,
    stopImpersonating,
    requiresAryeoLiteTermsAcceptance,
  }
}
