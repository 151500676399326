<template>
  <TransitionRoot :show="open" as="template" @after-leave="query = ''" appear>
    <Dialog as="div" class="relative z-30" @close="open = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 transition-opacity bg-gray-500/25" />
      </TransitionChild>

      <div class="fixed inset-0 z-30 p-4 overflow-y-auto sm:p-6 md:p-20">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="max-w-4xl mx-auto overflow-hidden transition-all transform bg-white divide-y divide-gray-100 shadow-2xl rounded-xl ring-1 ring-black ring-opacity-5"
          >
            <Combobox v-slot="{ activeOption }" @update:modelValue="onSelect">
              <CommandPaletteSearch :loading="loading" @change="handleSearchChange" />
              <CommandPaletteOptions
                :activeOption="activeOption"
                :optionGroupings="optionGroupings"
              />

              <div
                v-if="query !== '' && optionGroupings.length === 0"
                class="px-6 text-sm text-center py-14 sm:px-14"
              >
                <UsersIcon class="w-6 h-6 mx-auto text-gray-400" aria-hidden="true" />
                <p class="mt-4 font-semibold text-gray-900">No results</p>
                <p class="mt-2 text-gray-500">
                  We couldn&apos;t find anything with that term. Please try again.
                </p>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import CommandPaletteApi from '@/Services/Api/Company/V1/CommandPaletteApi'
import CommandPaletteOptions from '@/Components/Shared/CommandPalette/CommandPaletteOptions.vue'
import CommandPaletteSearch from '@/Components/Shared/CommandPalette/CommandPaletteSearch.vue'
import useAvailabilityNavigation from '@/Hooks/Company/Navigation/useAvailabilityNavigation'
import useCustomerNavigation from '@/Hooks/Company/Navigation/useCustomerNavigation.js'
import usePrimaryNavigation from '@/Hooks/Company/Navigation/usePrimaryNavigation'
import useProductNavigation from '@/Hooks/Company/Navigation/useProductNavigation'
import useSettingsNavigation from '@/Hooks/Company/Navigation/useSettingsNavigation'
import { Combobox, Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { UsersIcon, HomeIcon } from '@heroicons/vue/outline'
import { computed, onMounted, ref } from 'vue'
import { debounce, groupBy, flatten } from 'lodash'
import { router } from '@inertiajs/vue3'
import { useAuth } from '@/Hooks/Company/useAuth'
import useOrderFormNavigation from '@/Hooks/Company/Navigation/useOrderFormNavigation.js'
import useCustomerPortalNavigation from '@/Hooks/Company/Navigation/useCustomerPortalNavigation'

const open = ref(false)
const query = ref('')
const searchResults = ref([])
const loading = ref(false)

const { authCompany, authCompanyUser, impersonation, startImpersonating } = useAuth()
const { sections: primarySections } = usePrimaryNavigation()
const { sections: customerSections } = useCustomerNavigation()
const { sections: orderFormSections } = useOrderFormNavigation()
const { sections: customerPortalSections } = useCustomerPortalNavigation()
const { sections: settingSections } = useSettingsNavigation()
const { sections: availabilitySections } = useAvailabilityNavigation()
const { sections: productSections } = useProductNavigation()

function flattenNavigation(items, title = null) {
  const result = []

  function recursivelyFlatten(items, title = null) {
    for (const item of items) {
      if (item.show) {
        const sectionName = title ? `${title} > ${item.name}` : item.name

        if (item.children) {
          recursivelyFlatten(item.children, sectionName)
        } else {
          result.push({
            id: item.targetRouteName,
            name: sectionName,
            route: item.targetRouteName,
            routeIsInertia: item.routeIsInertia,
            icon: item.icon,
            type: 'ROUTE',
          })
        }
      }
    }
  }

  recursivelyFlatten(items, title)

  return result
}

const flattenedStaticNav = [
  {
    id: 'web.company.listings.create',
    name: 'Listings > Create Listing',
    route: 'web.company.listings.create',
    icon: HomeIcon,
    type: 'ROUTE',
  },
]
const flattenedPrimaryNav = flattenNavigation(primarySections)
const flattenedSettingsNav = flattenNavigation(settingSections, 'Settings')
const flattenedCustomersNav = flattenNavigation(customerSections, 'Customers')
const flattenedOrderFormNav = flattenNavigation(orderFormSections, 'Storefront')
const flattenedCustomerPortalNav = flattenNavigation(customerPortalSections, 'Storefront')
const flattenedAvailabilityNav = flattenNavigation(availabilitySections, 'Availability')
const flattenedProductNav = flattenNavigation(productSections, 'Products')

const groupings = [
  { name: 'Navigation', options: [], type: 'ROUTE' },
  { name: 'Customers', options: [], type: 'CUSTOMER' },
  { name: 'Customer Teams', options: [], type: 'CUSTOMER_TEAM' },
  { name: 'Listings', options: [], type: 'LISTING' },
  { name: 'Orders', options: [], type: 'ORDER' },
  { name: 'Switch to Another Company in Aryeo', options: [], type: 'COMPANY_TEAM_MEMBER' },
  { name: '[Aryeo Admin Only] Company Management', options: [], type: 'COMPANY' },
]

const filteredNavItems = computed(() => {
  return [
    ...flattenedPrimaryNav,
    ...flattenedStaticNav,
    ...flattenedCustomersNav,
    ...flattenedOrderFormNav,
    ...flattenedCustomerPortalNav,
    ...flattenedSettingsNav,
    ...(authCompany.value.id ? flattenedAvailabilityNav : []),
    ...(authCompany.value.id ? flattenedProductNav : []),
  ].filter((item) => item.name.toLowerCase().includes(query.value.toLowerCase()))
})

const optionGroupings = computed(() => {
  const groupedOptions = groupBy([...filteredNavItems.value, ...searchResults.value], 'type')

  return groupings.reduce((carry, grouping) => {
    if (groupedOptions[grouping.type]?.length > 0) {
      carry.push({
        ...grouping,
        options: groupedOptions[grouping.type],
      })
    }

    return carry
  }, [])
})

onMounted(() => {
  document.addEventListener('keydown', handleKeyDown)
})

function handleKeyDown(event) {
  const inputElementIsFocused = ['input', 'textarea', 'trix-editor'].includes(
    event.target.tagName?.toLowerCase()
  )
  if (!inputElementIsFocused && (event.key === '/' || (event.metaKey && event.key === 'k'))) {
    event.preventDefault()
    open.value = true
  }
}

function onSelect(option) {
  if (option.type === 'COMPANY') {
    // router.post(route('web.company.auth-company-user.selected-company-team-member.update'), {
    //   company_id: option.id
    // })
  }
  if (option.type === 'COMPANY_TEAM_MEMBER') {
    if (impersonation.value?.impersonated.id) {
      startImpersonating({
        resource: 'users',
        resourceId: option.meta.user_id,
        companyId: option.meta.company_id,
      })
      return
    }

    router.post(route('web.company.auth-company-user.selected-company-team-member.update'), {
      company_team_member_id: option.id,
    })
  }
  if (option.type === 'CUSTOMER') {
    router.get(route('web.company.customer-users.edit', option.id))
  }
  if (option.type === 'CUSTOMER_TEAM') {
    router.get(route('web.company.customer-teams.edit', option.id))
  }
  if (option.type === 'LISTING') {
    window.location = route('web.company.listings.edit', option.id)
  }
  if (option.type === 'ORDER') {
    window.location = route('web.company.orders.edit', option.id)
  }
  if (option.type === 'ROUTE') {
    if (option.routeIsInertia) {
      router.get(route(option.route))
    } else {
      window.location = route(option.route)
    }
  }

  open.value = false
}

function handleSearchChange(value) {
  query.value = value

  searchCommands()
}

const searchCommands = debounce(function () {
  if (query.value.length <= 1) {
    return
  }

  loading.value = true

  CommandPaletteApi.index(query.value)
    .then((res) => {
      searchResults.value = flatten(Object.values(res.data))
    })
    .finally(() => {
      loading.value = false
    })
}, 300)
</script>
