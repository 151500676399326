import { router } from '@inertiajs/vue3'

const maxCacheSize = 10
const pageCache = {}

export function addPageCache(component, pageProps) {
  pageCache[component] = pageProps

  if (pageCache.length > maxCacheSize) {
    const keys = Object.keys(pageCache)
    const index = keys.shift()
    delete pageCache[index]
  }
}

export function getPageCache(component) {
  return pageCache[component] || {}
}

export function setPage(page) {
  router.pushState(page)

  return Promise.resolve(router.resolveComponent(page.component)).then((component) => {
    router.swapComponent({ component, page }).then(() => {
      router.resetScrollPositions()
      document.dispatchEvent(new CustomEvent('inertia:navigate', { detail: { page } }))
    })
  })
}
