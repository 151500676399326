import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'

export function useAuth() {
  const page = usePage()

  const authCustomer = computed(() => page.props.auth?.customer)
  const authCustomerTeamMemberships = computed(() => page.props.auth?.customer_team_memberships)
  const authCustomerTeamMembership = computed(() => page.props.auth?.customer_team_membership)
  const authCustomerTeamMembers = computed(() => page.props.auth?.customer_team_members)
  const authCustomerTeamMember = computed(() => page.props.auth?.customer_team_member)
  const authCustomerTeam = computed(() => page.props.auth?.customer_team)
  const authCustomerUser = computed(() => page.props.auth?.customer_user)
  const authCustomerOrganization = computed(() => page.props.auth?.customer_organization)

  return {
    authCustomerUser,
    authCustomerTeamMemberships,
    authCustomerTeamMembership,
    authCustomerTeamMember,
    authCustomerTeamMembers,
    authCustomerTeam,
    authCustomer,
    authCustomerOrganization,
  }
}
