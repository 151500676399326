import {
  CreditCardIcon,
  DesktopComputerIcon,
  DocumentTextIcon,
  HomeIcon,
  PhoneIncomingIcon,
  UserAddIcon,
  UserCircleIcon,
  BellIcon,
  IdentificationIcon,
} from '@heroicons/vue/outline'

import { useTenant } from '@/Hooks/Shared/useTenant.js'
import { useAuth } from '@/Hooks/Customer/useAuth.js'

import { paymentsEnabled } from '@/Utils/useCompanySettings.js'

export default function useNavigation() {
  const { authCustomerOrganization, authCustomerTeam, authCustomerTeamMembership } = useAuth()
  const { tenant } = useTenant()

  return {
    overviewRouteName: null,
    sections: [
      {
        show: true,
        name: null,
        description: null,
        children: [
          {
            show: true,
            name: 'Dashboard',
            description: null,
            routeIsInertia: true,
            targetRouteName: 'web.customer.dashboard.show',
            icon: DesktopComputerIcon,
            routeNamesToCheckIfCurrent: ['web.customer.dashboard.*'],
          },
        ],
      },
      {
        show: true,
        name: 'General',
        description: null,
        children: [
          {
            show: true,
            name: 'Listings',
            description: null,
            routeIsInertia: true,
            targetRouteName: 'web.customer.listings.index',
            icon: HomeIcon,
            routeNamesToCheckIfCurrent: ['web.customer.listings.*'],
          },
          {
            show: authCustomerOrganization.value.is_pro_account,
            name: 'Orders',
            description: null,
            routeIsInertia: true,
            targetRouteName: 'web.customer.orders.index',
            icon: DocumentTextIcon,
            routeNamesToCheckIfCurrent: ['web.customer.orders.*'],
          },
          {
            show:
              tenant.value.features.show_leads_menu_item &&
              authCustomerOrganization.value.is_pro_account,
            name: 'Leads',
            description: null,
            routeIsInertia: true,
            targetRouteName: 'web.customer.leads.index',
            icon: PhoneIncomingIcon,
            routeNamesToCheckIfCurrent: ['web.customer.leads.*'],
          },
        ],
      },
      {
        show: authCustomerTeamMembership.value.status === 'active',
        name: 'Team Settings',
        description: null,
        children: [
          {
            show: true,
            name: 'Team',
            description: null,
            routeIsInertia: true,
            targetRouteName: 'web.customer.customer-teams.edit',
            targetRouteAttributes: {
              customerTeam: authCustomerTeam.value.id,
            },
            icon: UserAddIcon,
            routeNamesToCheckIfCurrent: [
              'web.customer.customer-teams.edit',
              'web.customer.customer-users.edit',
              'web.customer.customer-teams.products-and-pricing.edit',
            ],
          },
          {
            show:
              authCustomerTeamMembership.value.is_admin &&
              authCustomerOrganization.value.is_pro_account,
            name: 'Notifications',
            description: null,
            routeIsInertia: true,
            targetRouteName: 'web.customer.customer-teams.notifications.settings.index',
            targetRouteAttributes: {
              customerTeam: authCustomerTeam.value.id,
            },
            icon: BellIcon,
            routeNamesToCheckIfCurrent: ['web.customer.customer-teams.notification.settings.*'],
          },
        ],
      },
      {
        show: true,
        name: 'Personal Settings',
        description: null,
        children: [
          {
            show: true,
            name: 'User Details',
            description: 'Update your user details such as login email and password.',
            routeIsInertia: true,
            targetRouteName: 'web.customer.auth-customer-user.show',
            icon: UserCircleIcon,
            routeNamesToCheckIfCurrent: ['web.customer.auth-customer-user.*'],
          },
          {
            show: true,
            name: 'Social Profile Links',
            description: "Update your group's social profile links.",
            routeIsInertia: true,
            targetRouteName: 'web.customer.social-profiles.edit',
            icon: IdentificationIcon,
            routeNamesToCheckIfCurrent: ['web.customer.social-profiles.*'],
          },
          {
            show: paymentsEnabled() && authCustomerOrganization.value.is_pro_account,
            name: 'Payment Cards',
            description: null,
            routeIsInertia: true,
            targetRouteName: 'web.customer.payments.settings.payment-methods.index',
            icon: CreditCardIcon,
            routeNamesToCheckIfCurrent: ['web.customer.payments.settings.payment-methods.*'],
          },
          {
            show: authCustomerOrganization.value.is_pro_account,
            name: 'Notification Preferences',
            description: 'Configure your notification preferences.',
            routeIsInertia: true,
            targetRouteName: 'web.customer.notifications.preferences.edit',
            icon: BellIcon,
            routeNamesToCheckIfCurrent: ['web.customer.notifications.preferences.edit'],
          },
        ],
      },
    ],
  }
}
