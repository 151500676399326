<template>
  <ImpersonationSessionManager />

  <Box borderBottom="small" borderColor="secondary">
    <Breadcrumb />
  </Box>

  <div
    v-if="
      authOrganization &&
      !authOrganization?.is_pro_account &&
      !authOrganization?.aryeo_lite_learn_more_flag
    "
    style="max-width: 1368px"
    class="mx-auto"
  >
    <Box paddingTop="gutter" :paddingX="['medium', 'medium', 'gutter']">
      <AryeoLite />
    </Box>
  </div>

  <section style="max-width: 1368px" class="mx-auto">
    <Box paddingTop="gutter" :paddingX="['medium', 'medium', 'gutter']" paddingBottom="xxlarge">
      <SectionHeading />
      <slot />
    </Box>
  </section>
</template>

<script setup>
import { Box } from '@aryeo/runway'
import { useAuth } from '@/Hooks/Company/useAuth'

import ImpersonationSessionManager from '@/Components/Company/PageWrapper/ImpersonationSessionManager.vue'
import AryeoLite from '@/Components/Company/Organization/Banner/AryeoLite.vue'
import Breadcrumb from '@/Components/Company/PageWrapper/Breadcrumb.vue'
import SectionHeading from '@/Components/Shared/SectionHeading.vue'

const { authOrganization } = useAuth()
</script>
